import * as React from 'react';
import { GatsbyImage } from 'gatsby-plugin-image';

type PreviewCompatibleImageProps = {
  imageInfo: {
    alt: string;
    childImageSharp: {
      gatsbyImageData: any;
    };
    image: any;
  };
};

const PreviewCompatibleImage = ({ imageInfo }: PreviewCompatibleImageProps) => {
  const { alt = '', childImageSharp, image } = imageInfo;

  if (!!image && !!image.childImageSharp) {
    return <GatsbyImage image={image.childImageSharp.gatsbyImageData} alt={alt} />;
  } else if (!!childImageSharp) {
    return <GatsbyImage image={childImageSharp.gatsbyImageData} alt={alt} />;
    // for Netlify CMS
  } else if (image) {
    return <img src={image} alt={alt} />;
  } else {
    return null;
  }
};

export default PreviewCompatibleImage;

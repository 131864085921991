import React from 'react';
import { GatsbyImage, IGatsbyImageData } from 'gatsby-plugin-image';
import './FullWidthImage.scss';
import DangerouslySetInnerHtml from 'components/DangerouslySetInnerHtml';

type FullWidthImage = {
  img: IGatsbyImageData;
  title: string;
  height?: number | string;
  subheading?: string;
  imgPosition?: string;
};

export default function FullWidthImage({
  height = '65vh',
  img,
  title,
  subheading,
  imgPosition = 'center center',
}: FullWidthImage) {
  return (
    <>
      <div className="fullwidth-image">
        <GatsbyImage
          image={img}
          objectFit={'cover'}
          objectPosition={imgPosition}
          style={{
            gridArea: '1/1',
            maxHeight: height,
          }}
          loading="eager"
          alt={title}
        />
        {(title || subheading) && (
          <div className="fullwidth-image__content">
            {subheading && (
              <DangerouslySetInnerHtml
                className="fullwidth-image__description"
                html={subheading}
                data-sal="fade"
                data-sal-easing="ease"
                data-sal-duration={600}
                data-sal-delay={200}
              />
            )}
            {title && (
              <h1
                className="fullwidth-image__title"
                data-sal="fade"
                data-sal-easing="ease"
                data-sal-duration={600}
                data-sal-delay={400}
              >
                {title}
              </h1>
            )}
          </div>
        )}
      </div>
    </>
  );
}
